export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"66777bc18d4a0c002030d080",
				"66777bc18d4a0c002030d083"
			]
		},
		"66777bc18d4a0c002030d080": {
			"id": "66777bc18d4a0c002030d080",
			"name": "404",
			"pageUrl": "404"
		},
		"66777bc18d4a0c002030d083": {
			"id": "66777bc18d4a0c002030d083",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"max-height": "",
			"background": "",
			"height": "",
			"min-height": "",
			"width": "",
			"max-width": "",
			"display": "block",
			"position": "static",
			"overflow-x": "visible",
			"overflow-y": "",
			"font": "normal 100 20px/1.5 Beaufort for LOL"
		},
		"seo": {
			"customSitemapDomain": "https://everweave.ai",
			"title": "Everweave - Play D&D online",
			"robotsFileType": "autogenerated",
			"favicon_32px": "https://uploads.quarkly.io/66777bc18d4a0c002030d07b/images/favicon.png?v=2024-07-09T17:04:35.953Z"
		}
	}
}
